import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import ROUTES from '../../_constants/routes'
import { isProductEnabled, isFeatureEnabled } from '../../_helpers/flags'

const PrivateFactoringClientRoute = ({
  component: Component,
  loggedIn,
  isClient,
  financingType,
  bankingIntegrationEnabled,
  accounting_enabled,
  operate_enabled,
  password_reset_required,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          password_reset_required ? (
            <Redirect to={{ pathname: ROUTES.reset_password, state: { from: props.location } }} />
          ) : isClient ? (
            financingType === `factoring` ? (
              operate_enabled || (!operate_enabled && bankingIntegrationEnabled) ? (
                <Component {...props} />
              ) : (
                <Redirect to={{ pathname: ROUTES.confirm_form, state: { from: props.location } }} />
              )
            ) : (
              <Redirect to={{ pathname: ROUTES.abl_client_home, state: { from: props.location } }} />
            )
          ) : (
            <Redirect
              to={{
                pathname: accounting_enabled ? ROUTES.upload_invoices : ROUTES.confirm_form,
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect to={{ pathname: ROUTES.sign_in, state: { from: props.location } }} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  const { authentication, business, tenant, user, featureFlags } = state
  return {
    loggedIn: authentication.loggedIn,
    isClient: business.is_client,
    operate_enabled:
      tenant.operate_enabled ||
      (isProductEnabled('Operate', featureFlags) && isProductEnabled('Factoring', featureFlags)),
    bankingIntegrationEnabled:
      isFeatureEnabled('xen_premium', featureFlags) && isFeatureEnabled('banking_integration', featureFlags),
    financingType: business.financing_type,
    password_reset_required: user.reset_password_required,
  }
}

const connectedPrivateFactoringClientRoute = connect(mapStateToProps)(PrivateFactoringClientRoute)
export { connectedPrivateFactoringClientRoute as PrivateFactoringClientRoute }
