import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Select as MantineSelect } from '@mantine/core';
import { createLabelText } from '../_internal';
export const Select = forwardRef(({ hideLabel = false, label, scrollAreaProps = {}, ...props }, ref) => {
    const labelText = createLabelText(label, hideLabel, props.type);
    return (_jsx(MantineSelect, { ...props, ref: ref, ...labelText, scrollAreaProps: { scrollbarSize: '0.75rem', type: 'auto', ...scrollAreaProps } }));
});
Select.displayName = 'Select';
export {};
