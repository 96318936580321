import React from 'react'
import { plaidConstants } from '../../../_constants'
import { PlaidConnector } from './PlaidConnector'
import { useFlags } from '../../../_reduxHooks/flags.hook'
import { isFeatureEnabled } from '../../../_helpers/flags'

export const PlaidFormikField = ({ field, formikName, handleChange, handleBlur, value }) => {
  const { featureFlags } = useFlags()
  const isConnected = value === plaidConstants.STATUS_CONNECT
  const isBankIntegrationEnabled =
    isFeatureEnabled('xen_premium', featureFlags) && isFeatureEnabled('banking_integration', featureFlags)

  if (!isBankIntegrationEnabled) {
    return null
  }

  return (
    <PlaidConnector
      disconnectCb={() => {
        handleBlur(formikName, field.name, plaidConstants.STATUS_NOT_CONNECT)
        handleChange(formikName, plaidConstants.STATUS_NOT_CONNECT)
      }}
      isConnected={isConnected}
      successCb={() => {
        handleBlur(formikName, field.name, plaidConstants.STATUS_CONNECT)
        handleChange(formikName, plaidConstants.STATUS_CONNECT)
      }}
    />
  )
}
