import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ChangePassword } from 'legacy/_components/modals/changePassword';
import { Alert, Button, Center, CopyButton, Group, Heading, Stack, Text } from 'xen-ui';
import { useGetSalesPeople } from 'xen/api/use-sales-person';
import { useUpdateUser } from 'xen/api/use-user';
import { Page, PageTitle } from 'xen/components';
import { FormTextInput } from 'xen/components/form';
import { createInternalLinkUrl } from 'xen/helpers';
import { useUserContext } from 'xen/providers';
const UserUpdateFormSchema = z.object({
    emailAddress: z.string(),
    firstName: z.string().min(1, { message: 'First name required' }),
    lastName: z.string().optional(),
    title: z.string().optional(),
    phoneNumber: z.string().optional(),
});
const Profile = () => {
    const user = useUserContext();
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(UserUpdateFormSchema),
        mode: 'onBlur',
        values: {
            emailAddress: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            title: user.title,
            phoneNumber: user.phoneNumber,
        },
    });
    const { mutateAsync: updateUser } = useUpdateUser({ urlParams: { userId: user.id } });
    const onSubmit = async (data) => {
        await updateUser({ payload: { user: data } });
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    let mySignUpLink = '';
    const { data: salesPeople = [] } = useGetSalesPeople();
    const salesPerson = salesPeople.find((person) => person.user.id === user.id);
    if (salesPerson) {
        const signUpPath = createInternalLinkUrl({
            to: '/sign-up',
            searchParams: { sp: String(salesPerson.id) },
        });
        mySignUpLink = new URL(signUpPath, window.location.origin).toString();
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { title: "User profile" }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Profile" }), _jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsxs(Stack, { children: [_jsx(FormTextInput, { control: control, disabled: true, label: "Email", name: "emailAddress" }), _jsx(FormTextInput, { control: control, label: "First name", name: "firstName", required: true }), _jsx(FormTextInput, { control: control, label: "Last name", name: "lastName" }), _jsx(FormTextInput, { control: control, label: "Title", name: "title" }), _jsx(FormTextInput, { control: control, label: "Phone number", name: "phoneNumber" }), _jsx(Center, { children: _jsx(Button, { "data-testid": "update-profile-button", type: "submit", children: "Save" }) })] }) })] }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Security" }), _jsx(ChangePassword, { email: user.emailAddress, user_id: user.id })] }), mySignUpLink && (_jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "My sign up link" }), _jsx(Text, { children: "As a sales person, new users will be assigned to you when they use this link." }), _jsxs(Group, { children: [_jsx(Alert, { py: "xs", variant: "light", children: mySignUpLink }), _jsx(CopyButton, { value: mySignUpLink, children: ({ copied, copy }) => (_jsx(Button, { icon: copied ? 'CheckMark' : 'Copy', onClick: copy, variant: "outline", children: copied ? 'Copied' : 'Copy URL' })) })] })] }))] }));
};
export default Profile;
