import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Anchor, Button, Group, Stack, Text } from 'xen-ui';
import { SUPPORT_EMAIL } from 'xen/constants';
import { useAuthentication } from 'xen/hooks';
import { AUTH_PATHS } from 'xen/routes';
import { ErrorText } from '../error-text';
import { Page } from '../page';
import { PageTitle } from '../page-title';
export const Error = ({ message = 'Unknown error', title = 'Error' }) => {
    const { isLoggedIn } = useAuthentication();
    return (_jsx(Page, { "data-testid": 'fallback-error', children: _jsx(Alert, { color: "red", title: _jsx(ErrorText, { message: message }), variant: "light", children: _jsxs(Stack, { gap: "lg", children: [_jsxs(Stack, { gap: "xs", children: [_jsx(PageTitle, { title: title }), _jsxs(Text, { size: "lg", children: ["An unexpected error has occurred. Contact tech support at", ' ', _jsx(Anchor, { href: `mailto:${SUPPORT_EMAIL}`, underline: "always", children: SUPPORT_EMAIL }), "."] })] }), _jsxs(Group, { children: [isLoggedIn ? (_jsx(Button, { component: "a", href: AUTH_PATHS.signOut, children: "Sign out" })) : (_jsx(Button, { component: "a", href: AUTH_PATHS.signIn, children: "Sign in" })), _jsx(Button, { component: "a", href: AUTH_PATHS.signUp, variant: "outline", children: "Sign up" })] })] }) }) }));
};
