/**
 * NOTE: Update this config when you want to add new params or values
 */
const searchParamConfig = {
    /** open state for applicant 'accept terms' modal */
    acceptTerms: ['true', 'false'],
    /** open state for applicant 'add documents' modal */
    addDocuments: ['true', 'false'],
    /** open state for applicant 'admin' modal */
    admin: ['true', 'false'],
    /** open state for pre-qual form 'disclaimer' modal */
    disclaimer: ['true', 'false'],
    /** open state for applicant 'edit application' modal */
    edit: ['true', 'false'],
    /** pre-fill an email address on some auth forms */
    emailAddress: [],
    /** used to indicate current number of applicant's connected plaid accounts, so we can stop polling when a new account is found */
    integrationPlaidAccounts: [],
    /** open state for applicant 'print application' modal */
    print: ['true', 'false'],
    quoteCreated: ['true', 'false'],
    /** used to track the initial path visited by an un-authenticated user, so we can redirect there after auth */
    redirectTo: [],
    /** used during tenant enrollment, to automatically sign in user */
    sessionToken: [],
    /** sales person id, used to auto-assign sales person on sign up  */
    sp: [],
    /** used to store tab state in the URL, e.g. ?tab=users */
    tab: [],
    /** open state for applicant 'validate' modal */
    validate: ['true', 'false'],
    /** used during tenant enrollment to validate enrollment */
    verificationToken: [],
    /** @deprecated used in legacy applicant page to track which section is visible */
    view: [
        'brokers',
        'business-information',
        'notes',
        'quotes',
        'risk-and-fraud',
        'sign-documents',
        'status',
        'underwriting',
        'uploaded-documents',
    ],
    /** open state for applicant 'welcome' modal */
    welcome: ['true', 'false'],
    /** open state for applicant 'welcome password' modal */
    welcomePassword: ['true', 'false'],
};
// Type guard function
export const isValidSearchParam = (key, value) => {
    const keyValues = searchParamConfig[key];
    // If specific valid values are defined, check against them
    if (keyValues?.length > 0) {
        return keyValues.includes(value);
    }
    // If the array is empty, allow any string
    return typeof value === 'string';
};
