import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Table from '../../../_components/Table'
import { SearchableOverview } from '../../../_components/SearchableOverview'
import { ContentColumn } from '../../../_components/layout/ContentColumn'
import { PageContents } from '../../../_components/layout/PageContents'
import { Button } from '../../../_components/Button'
import { createColumn } from '../../../_components/TableColumn'
import { Box } from '../../../components/Box/Box'
import { CreateBrokerModal } from '../../common/NewProspect/ProspectBrokers/CreateBrokerModal/CreateBrokerModal'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { useState } from 'react'

// Styles
import { H1 } from '../../../_components/Typography/Components/Headings/H1'

// Services
import { getTenantBrokers } from '../../../_services'

import { LENDER_ROUTES } from '../../../_constants/routes'
import { COMPANY_NAME } from 'xen/constants'

export const Brokers = () => {
  const { openModal } = useModal()
  const [reloadTable, setReloadTable] = useState(null)

  const brokersTableColumns = [
    createColumn({
      accessor: 'id',
      headingText: 'Id',
    }),
    createColumn({
      accessor: 'name',
      headingText: 'Name',
    }),
    {
      accessor: `na`,
      headingText: '',
      disableSortBy: true,
      maxWidth: 120,
      Cell: (props) => (
        <div className={`align-right`}>
          <Button
            buttonType="link"
            className="compact"
            text="View"
            to={`${LENDER_ROUTES.brokers}/${props.row.original.id}`}
          />
        </div>
      ),
    },
  ]

  return (
    <PageContents>
      <Helmet>
        <title>Brokers • {COMPANY_NAME}</title>
      </Helmet>
      <ContentColumn>
        <Box alignItems={'flex-start'} d={'flex'} justifyContent={'space-between'} mb="35px">
          <H1>Brokers</H1>
          <Button
            onClick={() => {
              openModal(
                CreateBrokerModal,
                {},
                {
                  onClose: () => {
                    reloadTable()
                  },
                }
              )
            }}
          >
            Create Broker
          </Button>
        </Box>

        <SearchableOverview
          searchPlaceholder="Search Brokers"
          showSearchBar={true}
          views={[
            {
              endpointCall: async (searchParams) => {
                const sortDirectionSet = searchParams.has('sort_asc')
                if (!sortDirectionSet) searchParams.set('sort_asc', false)
                return getTenantBrokers(searchParams).then((response) => {
                  return {
                    results: response['data'],
                    total_results: response.headers['total-count'],
                  }
                })
              },
              columns: brokersTableColumns,
              requestMethod: 'GET',
              Component: ({ results, columns, sort, handleSortChange, pageSize, totals }) => (
                <Table
                  className="with-border"
                  columns={columns}
                  data={results}
                  defaultSort={[{ id: 'id', desc: false, disableNoSort: true }]}
                  onSortedChange={handleSortChange}
                  pageSize={pageSize}
                  sorted={sort}
                  totals={totals}
                />
              ),
              fetchReload: (fn) => {
                setReloadTable(() => fn)
              },
            },
          ]}
        />
      </ContentColumn>
    </PageContents>
  )
}
