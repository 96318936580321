import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { BasicTable, Center, Group, Heading, Loader, Select, Stack, Text } from 'xen-ui';
import { useGetAccounts } from 'xen/api/use-integration-plaid';
import { formatCurrency, formatDate, searchParam } from 'xen/helpers';
import { PlaidDisconnect } from './plaid-disconnect';
import { PlaidTransactions } from './plaid-transactions';
const accountDisplayName = (account) => {
    return `${account.institution.name}: ${account.name}`;
};
const buildAccountData = (account) => {
    return { value: account.id, label: accountDisplayName(account) };
};
const buildAccountsData = (accounts) => {
    return accounts.map((account) => {
        return buildAccountData(account);
    });
};
export const PlaidAccounts = ({ onAccountSelected, entityId, entityType = 'tenant', selectedAccount, }) => {
    const accountsParam = searchParam.get('integrationPlaidAccounts');
    const queryParams = { entityId: entityId, entityType: entityType };
    const hasAccountsParam = accountsParam !== '';
    const refetchInterval = hasAccountsParam ? 3000 : undefined;
    const { data: accounts = [], isLoading, isError, isRefetching } = useGetAccounts({ queryParams, refetchInterval });
    const accountsData = buildAccountsData(accounts);
    const defaultAccount = accounts[0] || null;
    useEffect(() => {
        if (hasAccountsParam && accounts.length > Number(accountsParam)) {
            searchParam.remove('integrationPlaidAccounts');
        }
    }, [accountsParam, accounts]);
    useEffect(() => {
        // By default, select the first account in the list
        onAccountSelected(defaultAccount);
    }, [accounts]);
    if (isError)
        return _jsx("div", { children: "Error getting accounts. Please try again." });
    if (isLoading)
        return _jsx("div", { children: "Loading..." });
    if (accounts.length === 0) {
        return (_jsx(Stack, { "data-testid": "no-accounts", children: _jsx(Center, { bg: "gray.0", h: 50, children: hasAccountsParam ? _jsx(Loader, {}) : _jsx(Text, { children: "No linked bank accounts" }) }) }));
    }
    const handleAccountSelected = (value) => {
        const account = accounts.find((account) => account.id === value) || null;
        onAccountSelected(account);
    };
    return (_jsxs(Stack, { children: [_jsxs(Group, { pb: "xs", children: [_jsx(Select, { allowDeselect: true, data: accountsData, label: "Account", onOptionSubmit: handleAccountSelected, searchable: true, styles: (theme) => ({
                            label: { fontSize: theme.fontSizes.md },
                            root: { display: 'flex', alignItems: 'center', gap: theme.spacing.md, flex: 1 },
                            wrapper: { flexGrow: 1 },
                        }), value: selectedAccount?.id }), selectedAccount && (_jsx(PlaidDisconnect, { entityId: entityId, entityType: entityType, institutionId: selectedAccount.institution.id })), (isRefetching || hasAccountsParam) && _jsx(Loader, {})] }), selectedAccount && (_jsxs(_Fragment, { children: [_jsx(Heading, { level: 2, styleVariant: 5, children: "Details" }), _jsx(BasicTable.ScrollArea, { "aria-label": "Details", children: _jsxs(BasicTable, { children: [_jsx(BasicTable.Thead, { children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { children: "Bank" }), _jsx(BasicTable.Th, { children: "Account Name" }), _jsx(BasicTable.Th, { children: "Account Number" }), _jsx(BasicTable.Th, { children: "First Transaction" }), _jsx(BasicTable.Th, { children: "Account Type" }), _jsx(BasicTable.Th, { children: "Current Balance" })] }) }), _jsx(BasicTable.Tbody, { style: { verticalAlign: 'baseline' }, children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Td, { children: selectedAccount.institution.name }), _jsx(BasicTable.Td, { children: selectedAccount.officialName }), _jsxs(BasicTable.Td, { children: ["****", selectedAccount.mask] }), _jsx(BasicTable.Td, { children: selectedAccount.firstTransactionDate
                                                    ? formatDate.localeDateTimeString(selectedAccount.firstTransactionDate)
                                                    : '' }), _jsxs(BasicTable.Td, { children: [selectedAccount.accountType, " (", selectedAccount.accountSubtype, ")"] }), _jsxs(BasicTable.Td, { children: [formatCurrency(selectedAccount.balance.current), " ", selectedAccount.balance.isoCurrencyCode] })] }) })] }) }), _jsx(PlaidTransactions, { accountId: selectedAccount.id })] }))] }));
};
