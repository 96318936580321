export const COMPANY_NAME = 'XEN';
export const DEFAULT_BACKGROUND_COLOR = '#383F43';
export const DEFAULT_FAVICON = 'https://finvoice-tenants.s3.amazonaws.com/finvoice-app/prod/demo/favicon.ico';
export const DEFAULT_PRIMARY_COLOR = '#043754';
export const DEFAULT_PRIMARY_CONTRAST_COLOR = '#FFFFFF';
export const DEFAULT_SECONDARY_COLOR = '#00AAE0';
export const DEFAULT_TENANT_LOGO = 'https://finvoice-tenants.s3.amazonaws.com/finvoice-app/prod/demo/header_logo.png';
export const LOCAL_STORAGE_PREFIX = 'xen-ff:';
export const PRIVACY_NOTICE_URL = 'https://www.xenplatforms.com/privacy-notice';
export const SUPPORT_EMAIL = 'support@xenplatforms.com';
export const TERMS_AND_CONDITIONS_URL = 'https://www.xenplatforms.com/terms-and-conditions';
